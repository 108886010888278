import {
    ACTION_BULLETIN_BOARD_CATEGORY_SELECT,
    ACTION_BULLETIN_BOARD_POST_SEARCH_REQUEST,
    ACTION_BULLETIN_BOARD_POST_SEARCH_RESULT,
    ACTION_BULLETIN_BOARD_POST_SELECT,
    ACTION_BULLETIN_BOARD_FORUM_SELECT
} from './type'

export function categorySelect(category_id) {
    return {
        type: ACTION_BULLETIN_BOARD_CATEGORY_SELECT,
        category_id
    }
}

export function postSearchRequest(key) {
    return {
        type: ACTION_BULLETIN_BOARD_POST_SEARCH_REQUEST,
        key
    }
}

export function postSearchResult(key, result) {
    return {
        type: ACTION_BULLETIN_BOARD_POST_SEARCH_RESULT,
        key,
        result,
        updated: Date.now()
    }
}

export function postSelect(post_id) {
    return {
        type: ACTION_BULLETIN_BOARD_POST_SELECT,
        post_id
    }
}

export function forumSelect(forum_id) {
    return {
        type: ACTION_BULLETIN_BOARD_FORUM_SELECT,
        forum_id
    }
}
