import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';
import { postSelect } from '../../redux/action/bulletinBoard';

/*
* Redirect Bulleting board post from /post/:post_id to /message-board
* Open post post_id
*/
function _Redirect(props) {
    const { dispatch, match } = props;
    if (match.params.post_id) {
        dispatch(postSelect(match.params.post_id))
    }
    return <Redirect to="/message-board" />
}

const _ReduxRedirect = compose(connect())(_Redirect);

function PostRedirect() {
    return <Route exact path="/post/:post_id" render={(props) => <_ReduxRedirect {...props} />} />
}

export default PostRedirect;
